import React from 'react';
import { Routes, Route } from "react-router-dom";
import Header from './components/Header'
import HomePage from './containers/pages/Home/Index'
import GraniteIndex from './containers/pages/Granite/Index'
import TombstoneIndex from './containers/pages/Tombstone/Index'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import StairIndex from './containers/pages/Stairs/Index';
import SillIndex from './containers/pages/Sills/Index';
import CountertopIndex from './containers/pages/Countertops/Index';
import { grey } from '@mui/material/colors';

const hx = {
  fontFamily: "'Crimson Text', serif"
}

let theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: grey[900]
        }
      }
    }
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    h1: hx,
    h2: hx,
    h3: hx,
    h4: hx,
    h5: hx,
    h6: hx,
  }
});

theme = responsiveFontSizes(theme);

function App() {
  return <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/countertops" element={<CountertopIndex />} />
        <Route path="/stairs" element={<StairIndex />} />
        <Route path="/sills" element={<SillIndex />} />
        <Route path="/granites" element={<GraniteIndex />} />
        {/* <Route path="/tombstones" element={<TombstoneIndex />} /> */}
      </Routes>
    </ThemeProvider>
}

export default App;
