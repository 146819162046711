import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function SillIndex() {

  const numbers = Array.from(Array(13).keys());

  const imageRender = (i:number) => {
    const graphImage = require(`../../../../gravestone_images/${i+1}.jpg`)
    return <img width="100%" src={graphImage} />
  }

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Typography variant="h1">
          Parapety granitowe
        </Typography>
        {numbers.map(imageRender)}
      </Container>
    </React.Fragment>
  );
}