import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function GraniteIndex() {

  const numbers = Array.from(Array(24).keys());

  const imageRender = (i:number) => {
    const graphImage = require(`../../../../granites/${i+1}.jpg`)
    return <img width="100%" src={graphImage} />
  }

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Typography variant="h1">
          Granity
        </Typography>
        <Typography>
          Granit to rodzaj magmowej skały głębinowej, która powstaje w wyniku krystalizacji magmy wewnątrz Ziemi. Jest to jeden z najczęściej spotykanych rodzajów skał na Ziemi i występuje w różnych częściach świata.
        </Typography>
        <Typography>
          Granit jest zbudowany z minerałów takich jak kwarc, feldspary i miki. Charakteryzuje się twardością i odpornością na uszkodzenia mechaniczne, co czyni go popularnym materiałem budowlanym do wykorzystania w postaci płyt, krawężników, schodów, kamiennych blatów kuchennych i łazienkowych, a także w elementach dekoracyjnych.
        </Typography>
        <Typography>
          Granit jest także wykorzystywany jako kamień ozdobny do dekoracji wewnętrznych i zewnętrznych, a także do wyrobu rzeźb i pomników. Ma wiele różnych barw i wzorów, w zależności od składu mineralnego i pochodzenia geologicznego.
        </Typography>
        <Typography>
          Granit jest też często używany jako materiał do produkcji blatów kuchennych i łazienkowych, ze względu na swoją trwałość i odporność na zarysowania oraz uszkodzenia termiczne.
        </Typography>
        <Typography>
          Wiele zabytków i budynków na całym świecie zostało zbudowanych z granitu, co czyni go ważnym elementem dziedzictwa kulturowego ludzkości.
        </Typography>
        {numbers.map(imageRender)}
      </Container>
    </React.Fragment>
  );
}