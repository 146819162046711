import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import iStock1 from './iStock-153816925.jpg'
import iStock2 from './iStock-494351414.jpg'
import iStock3 from './iStock-1340089261.jpg'
import iStock4 from './iStock-95001487.jpg'
import iStock5 from './iStock-157484184.jpg'
import countertop_l_type from '../../../../countertop_types/countertop_l_type.png'
import countertop_u_type from '../../../../countertop_types/countertop_u_type.png'
import countertop_simple_type from '../../../../countertop_types/countertop_simple_type.png'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DraftsIcon from '@mui/icons-material/Drafts';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import AddBoxTwoTone from '@mui/icons-material/AddBoxTwoTone';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Typography from '@mui/material/Typography';
import Faq from './Faq'

export function BasicList() {
  return (
    <Box sx={{ width: '100%'}}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InvertColorsIcon />
              </ListItemIcon>
              <ListItemText primary="Całkowita odporność na wilgoć" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Odporny na uszkodzenia mechaniczne" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemIcon>
                <AddBoxTwoTone />
              </ListItemIcon>
              <ListItemText primary="odporny na wysokie temperatury" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemIcon>
                <DangerousIcon />
              </ListItemIcon>
              <ListItemText primary="łatwość w utrzymaniu w czystości " />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
  );
}


export default function CountertopIndex() {
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Typography variant="h1">
          Blaty granitowe
        </Typography>
        <Typography variant="subtitle1">
          Produkujemy wysokiej jakości blaty granitowe dopasowane do mebli Ikea.
        </Typography>
        <img src={countertop_simple_type} width="50%" />
        <img src={countertop_l_type} width="50%" />
        <img src={countertop_u_type} width="50%" />
        <img src={iStock1} width="100%" />
        <img src={iStock3} width="100%" />
        <BasicList />
        <img src={iStock2} width="100%" />
        <img src={iStock4} width="100%" />
        <Typography variant="h3" align="center" sx={{my: 12, mx: 6}}>
          W naszej ofercie znajdziesz blaty granitowe pasujące do systemów meblowych IKEA METOD i PAX. Możesz je również zamówić w indywidualnym rozmiarze do bazy kuchni od stolarza lub innego producenta.
        </Typography>
        <img src={iStock5} width="100%" />
        <img src="https://media.istockphoto.com/photos/classic-kitchen-in-vintage-room-with-moldings-wall-luxury-interior-picture-id956891538?k=20&m=956891538&s=612x612&w=0&h=bDpH88N48Fjeb9rcYeyOvEGtuaxrsE4LqvZMNWsFeik=" />
        <img src="https://media.istockphoto.com/photos/modern-black-sink-in-contemporary-kitchen-interior-design-picture-id1367863913?k=20&m=1367863913&s=612x612&w=0&h=vyZg1os_h0hA52Z-9eyDU1HfHBu6qbBut8sp6BsFf-M=" />
        <img src="https://media.istockphoto.com/photos/modern-luxury-kitchen-condo-apartment-picture-id498193574?k=20&m=498193574&s=612x612&w=0&h=o8G6vi2nAvQBkKW9C5In3Och7B6wtWFAjek8fLqqBMI=" />
        <img src="https://media.istockphoto.com/photos/kitchen-in-new-luxury-home-with-open-floorplan-picture-id489036544?k=20&m=489036544&s=612x612&w=0&h=MmYJw4tjOiRzJOO5vGzrUVBrbh2jToIRdVXuVqbUMSo=" />
        <img src="https://media.istockphoto.com/photos/new-classic-kitchen-in-modern-style-a-new-sink-in-kitchen-picture-id1191804036?k=20&m=1191804036&s=612x612&w=0&h=m-1aDQ0cKx_z6augtbH0pk90Bs_e9kRRi1JfPuQJhI4=" />
        <img src="https://media.istockphoto.com/photos/beautiful-kitchen-in-new-luxury-home-picture-id489036584?k=20&m=489036584&s=612x612&w=0&h=LBGx41j0yR80_CQgew_5XhobcAQk-veXpqGaoeQGsvo=" />
        <Faq />
      </Container>
    </React.Fragment>
  );
}