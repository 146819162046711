import * as React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const StyledButton = styled(Button)<any>(({ theme }) => ({
  color: '#fff',
  padding: '10px 13px',
  fontSize: theme.typography.pxToRem(17)
}));

function AppBarButton({to, children}:{to:string, children:any}){
  return <StyledButton component={Link} to={to}>{children}</StyledButton>
}

export default function MainAppBar() {
  return (
    <AppBar elevation={0} position="static">
      <Container maxWidth="xl">
        <Toolbar>

          
          <AppBarButton to='/countertops'>Blaty</AppBarButton>
          <AppBarButton to='/stairs'>Schody</AppBarButton>
          <AppBarButton to='/sills'>Parapety</AppBarButton>
          {/* <AppBarButton to='/tombstones'>Nagrobki</AppBarButton> */}
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' }, mr: 2 }}
          >
            GRANITEO
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <AppBarButton to='/granites'>Rodzaje kamieni</AppBarButton>
            <AppBarButton to='/contact'>Kontakt</AppBarButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
